<template>
    
    <v-card-header>
        <div>
        <div class="text-overline mb-1">
            Kontaktieren Sie uns
        </div>
        <a style="text-decoration: none; color: inherit;" href="https://www.google.at/maps/dir//Auto+Winkler,+Hans-List-Stra%C3%9Fe+3,+8750+Judenburg/@47.183655,14.6691054,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x410799b5b8ac6069:0x97f275c42d3ed439!2m2!1d14.6712941!2d47.183655!3e0" target="_blank">
            <div class="text-h6 mt-1">Adresse<v-icon size="small">mdi-map-marker-outline</v-icon></div>
            <div class="text-caption">Hans-List-Straße 3 | 8750 Judenburg</div>
        </a>
        <a style="text-decoration: none; color: inherit;" href="tel:+436641873874">
            <div class="text-h6 mt-1">Telefon<v-icon size="small">mdi-phone-outline</v-icon></div>
            <div class="text-caption">+43 664 18 73 874</div>
        </a>
        <a style="text-decoration: none; color: inherit;" href="mailto:paul@autowinkler.at">
            <div class="text-h6 mt-1">E-Mail<v-icon size="small">mdi-email-outline</v-icon></div>
            <div class="text-caption">paul@autowinkler.at</div>
        </a>
        <a style="text-decoration: none; color: inherit;" href="https://www.autowinkler.at" target="_blank">
            <div class="text-h6 mt-1">Web<v-icon size="small">mdi-web</v-icon></div>
            <div class="text-caption">www.autowinkler.at</div>
        </a>
        </div>
    </v-card-header>
</template>
// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'cpaCustomTheme',
    themes: {
      cpaCustomTheme: {
        dark: false,
        colors: {
          yellow: '#f4e600',
          blue: '#003399'
        }
      }
    }
  }
})

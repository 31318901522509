<template>
  <v-app id="inspire">
    <v-app-bar app color="blue">
      <v-app-bar-nav-icon class="d-flex d-md-none" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn class="d-none d-md-flex" icon="mdi-home-outline" title="zurück zur Startseite" href="https://www.autowinkler.at/"></v-btn><v-toolbar-title class="d-none d-md-flex">Auto Ankauf - Autohaus Winkler</v-toolbar-title>
      <v-toolbar-title class="d-flex d-md-none">Auto Ankauf</v-toolbar-title>
      <v-img
        max-height="150"
        max-width="250"
        src="https://www.autowinkler.at/wp-content/uploads/2020/11/cropped-Autohaus-Winkler-GmbH-Judenburg-Logo.png"
        alt="logo"
      ></v-img>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      class="d-flex d-md-none"
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in navItems"
          :key="item.title"
          link
          :href="item.to"
        >
          
          <v-icon start>{{ item.icon }}</v-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <contact-us-card></contact-us-card>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="8"
          >
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
              <div>
                <v-breadcrumbs :items="stepItems">
                  <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                  </template>
                </v-breadcrumbs>
              </div>

              <v-alert v-if="errorMessage" class="mx-10" density="comfortable" type="error" variant="contained-text">
                {{ errorMessage }}
              </v-alert>

              <div class="pa-10">
                
                <div v-if="curStep === 1">
                  <v-combobox label="Marke*" v-model="carData.brand" :items="brandList" :error="errorFields.includes('[brand]')"></v-combobox>
                  <v-text-field label="Modell*" v-model="carData.model" :error="errorFields.includes('[model]')"></v-text-field>
                  <v-text-field type="month" label="Erstzulassung*" :max="maxMonthDate" v-model="carData.firstRegistrationDate" :error="errorFields.includes('[firstRegistrationDate]')"></v-text-field>
                  <v-text-field type="number" label="Kilometerstand*" suffix="km" min="0" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" v-model="carData.distance" :error="errorFields.includes('[distance]')"></v-text-field>
                  <v-text-field type="number" label="Leistung*" suffix="PS" min="0" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" v-model="carData.power" :error="errorFields.includes('[power]')"></v-text-field>
                  <v-radio-group label="Treibstoff*" v-model="carData.fuelType" :error="errorFields.includes('[fuelType]')" inline>
                    <v-radio label="Diesel" value="Diesel"></v-radio>
                    <v-radio label="Benzin" value="Benzin"></v-radio>
                    <v-radio label="Elektro" value="Elektro"></v-radio>
                    <v-radio label="Gas" value="Gas"></v-radio>
                    <v-radio label="Hybrid" value="Hybrid"></v-radio>
                    <v-radio label="Wasserstoff" value="Wasserstoff"></v-radio>
                  </v-radio-group>
                  <v-radio-group label="Antrieb*" v-model="carData.driveType" :error="errorFields.includes('[driveType]')" inline>
                    <v-radio label="Front" value="Front"></v-radio>
                    <v-radio label="Heck" value="Heck"></v-radio>
                    <v-radio label="Allrad" value="Allrad"></v-radio>
                  </v-radio-group>
                  <v-radio-group label="Getriebe*" v-model="carData.gearType" :error="errorFields.includes('[gearType]')" inline>
                    <v-radio label="Schalter" value="Schalter"></v-radio>
                    <v-radio label="Automatik" value="Automatik"></v-radio>
                  </v-radio-group>
                </div>

                <div v-if="curStep === 2">
                  <v-card
                    class="mx-auto mb-10"
                    v-if="carData.pictures.length > 0"
                  >
                    
                    <v-container class="pa-1">
                      <v-item-group
                        v-model="selection"
                        multiple
                      >
                        <v-row>
                          <v-col
                            v-for="picture in carData.pictures"
                            :key="picture.ind"
                            cols="12"
                            md="6"
                          >
                            <v-item>
                              <v-img
                                :src="picture.url"
                                height="150"
                                class="text-right pa-2"
                              >
                                <v-btn @click="removeFile(picture.ind)" color="error" icon="mdi-close" title="Entfernen"></v-btn>
                              </v-img>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </v-container>
                  </v-card>

                  <input type="file" ref="imageInput" @change="addFiles" accept="image/*" style="display : none" multiple>
                  <center>
                    <v-btn @click="$refs.imageInput.click()" icon="mdi-camera-plus" size="x-large" rounded="lg" height="100" width="100" class="mb-10"></v-btn>
                  </center>
                </div>

                <div v-if="curStep === 3">
                  <v-text-field label="Vorname" v-model="carData.contact.firstName" :error="errorFields.includes('[firstName]')" clearable></v-text-field>
                  <v-text-field label="Nachname" v-model="carData.contact.surName" :error="errorFields.includes('[surName]')" clearable></v-text-field>
                  <v-text-field type="phone" label="Telefon" v-model="carData.contact.phone" :error="errorFields.includes('[phone]')" clearable></v-text-field>
                  <v-text-field type="email" label="E-Mail" v-model="carData.contact.email" :error="errorFields.includes('[email]')" clearable></v-text-field>
                </div>

                <div v-if="curStep === 4">
                  <v-textarea label="Zusätzliche Anmerkungen (max. 400 Zeichen)" v-model="carData.remarks" :rules="[v => (v || '' ).length <= 400 || 'Die Anmerkung darf 400 Zeichen nicht überschreiten.']" prepend-inner-icon="mdi-comment"></v-textarea>
                  <v-checkbox v-model="carData.acceptPrivacy" :error="errorFields.includes('[acceptPrivacy]')">
                    <template v-slot:label>
                      <div style="word-wrap: break-word;">
                        Ich akzeptiere die <a href="https://www.autowinkler.at/datenschutzerklaerung/"><b>Datenschutzerklärung</b></a><br> und stimme der Nutzung meiner Daten zu!
                      </div>
                    </template>
                  </v-checkbox>
                </div>

                <div v-if="curStep > 0 && curStep < 5">
                  <v-row id="controller" align="center" justify="space-around">
                    <v-btn v-if="curStep > 1" @click="priorStep" color="yellow">Zurück</v-btn>
                    <v-btn v-if="curStep < 4" @click="nextStep" color="yellow">Weiter</v-btn>
                    <v-btn v-if="curStep >= 4" @click="sendCarData" color="yellow">Senden<v-icon>mdi-send</v-icon></v-btn>
                  </v-row>
                </div>
                <div v-else-if="curStep === 5" class="w-100">
                  <v-alert density="comfortable" type="success" variant="contained-text">
                    Fahrzeugdaten erfolgreich übermittelt
                  </v-alert>
                  <p class="font-weight-light pa-10">
                  Vielen Dank!<br>
                  Wir werden uns sobald wie möglich bei Ihnen melden.<br><br>
                  Mit freundlichen Grüßen<br>
                  <b>Ihr Autohaus Winkler Team</b>
                  </p>
                  <v-row align="center" justify="space-around">
                    <v-btn class="ma-2" @click="setDefaultCarData" color="yellow">Weiters Fahrzeug senden</v-btn>
                    <v-btn class="ma-2" href="https://autowinkler.at/" color="blue">zu Autohaus Winkler</v-btn>
                  </v-row>
                </div>
              </div>
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            sm="2"
            class="mt-16 d-none d-md-flex"
          >
            <v-sheet
              rounded="lg"
              min-height="268"
              class="mb-auto"
            >
              <v-card
              max-width="344"
              min-width="250"
              variant="outlined"
              >
                <contact-us-card></contact-us-card>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-dialog
      v-model="isSendLoading"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-text>
          {{ loadingStatusText }}
          <v-progress-linear
            indeterminate
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-footer
      color="blue"
      class="d-none d-md-flex"
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          v-for="item in navItems"
          :key="item.tile"
          color="white"
          variant="text"
          class="mx-2"
          rounded="xl"
          :href="item.to"
        >
          {{ item.title }}
        </v-btn>
        <v-col
          class="text-center text-white mt-4"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>Autohaus Winkler GmbH</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  import ContactUsCard from './components/ContactUsCard.vue'
  import CpaDataService from './services/CpaDataService'

  export default {
    metaInfo: {
      title: 'Ankauf - Autohaus Winkler'
    },
    components: { ContactUsCard},
    data: () => (
      {
        drawer: null,
        navItems: [
            { title: 'Startseite', icon: 'mdi-home-outline', to: 'https://www.autowinkler.at/' },
            { title: 'Kaufen', icon: 'mdi-car-hatchback', to: 'https://www.autowinkler.at/fahrzeuge/' },
            { title: 'Verkaufen', icon: 'mdi-currency-usd', to: '#' },
            { title: 'Über uns', icon: 'mdi-information-outline', to: 'https://www.autowinkler.at/ueber-uns/' },
            { title: 'Datenschutzerklärung', icon: 'mdi-safe-square-outline', to: 'https://www.autowinkler.at/datenschutzerklaerung/' },
            { title: 'Impressum', icon: 'mdi-file-document-outline', to: 'https://www.autowinkler.at/impressum/' }
          ],
        carData: {
          brand: '',
          model: '',
          firstRegistrationDate: '',
          distance: '',
          power: '',
          driveType: null,
          gearType: null,
          fuelType: null,
          pictures: [],
          contact: {
            firstName: '',
            surName: '',
            phone: '',
            email: ''
          },
          remarks: '',
          acceptPrivacy: false,
          dataUploadedFinished: false
        },
        curStep: 1,
        isSendLoading: false,
        loadingStatus: '',
        errorMessage: '',
        stepError: '',
        maxMonthDate: (new Date(Date.now()).toISOString().substring(0, 7)),
        maxImageFileNum: 20,
        uploadedImages: 0,
        errorFields: [],
        brandList: ["VW", "Skoda","SEAT","BMW","Audi","Mercedes-Benz","Ford", "Abarth","Aiways","Aixam","Alfa Romeo","ALPINA","Alpine","Artega","Aston Martin","Auto Union",
                    "Bentley","Borgward","Brilliance","Bugatti","Cadillac","Casalini","Chevrolet","Chrysler","Citroen",
                    "CUPRA","Dacia","Daewoo","DAF","Daihatsu","Datsun","DKW","Dodge","Donkervoort","DS Automobiles",
                    "e.GO Mobile","Elaris","Ferrari","Fiat","Fisker","Genesis","Glas","Gumpert","Honda","Hummer",
                    "Hyundai","Hyundai Precision","Infiniti","Isuzu","Jaguar","Jeep","KIA","Koenigsegg","Lada","Lamborghini",
                    "Lancia","Land Rover","Lexus","Ligier","Lotus","Lynk & Co","Maserati","Maxus","Maybach","Mazda","McLaren",
                    "MG","Micro","Microcar","MINI","Mitsubishi","Morgan","Nissan","NSU","Opel","Pagani",
                    "Peugeot","Polestar","Porsche","Proton","Renault","Rolls-Royce","Rover","Saab", "smart",
                    "Sono Motors","SsangYong","Subaru","Suzuki","Talbot","Tesla","Toyota","Trabant","Volvo","Wartburg",
                    "Wiesmann","Yugo","Sonstiges"]
      }
    ),
    computed: {
      stepItems() {
        return [
          {
            text: 'Fahrzeug',
            disabled: this.curStep < 1,
            color: (this.curStep === 1) ? 'primary' : null
          },
          {
            text: 'Fotos',
            disabled: this.curStep < 2,
            color: (this.curStep === 2) ? 'primary' : null
          },
          {
            text: 'Kontakt',
            disabled: this.curStep < 3,
            color: (this.curStep === 3) ? 'primary' : null
          },
          {
            text: 'Fertig',
            disabled: this.curStep < 4,
            color: (this.curStep === 4) ? 'primary' : null
          }
        ]
      },
      loadingStatusText() {
        if (this.loadingStatus === 'car-picture-upload')
          return 'Bilder werden hochgeladen (' + this.uploadedImages + '/' + this.carData.pictures.length + ')'
        
        return 'Fahrzeugdaten werden überprüft'
      }
    },
    methods: {
      addFiles(e) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.carData.pictures.push(
            {
              ind: this.carData.pictures.length,
              url: URL.createObjectURL(e.target.files[i]),
              file: e.target.files[i],
              uploaded: false,
              status: ''
            }
          )
        }

        this.errorMessage = ''
        if (!this.isStepDataValid(2)) {
          this.errorMessage = this.stepError
          location.href='#'
          window.scrollTo(0,0)
        } else {
          location.href='#controller'
        }
      },
      removeFile(indToDelete) {
        this.carData.pictures = this.carData.pictures.filter(i => i.ind !== indToDelete)

        this.errorMessage = ''
        if (!this.isStepDataValid(2)) {
          this.errorMessage = this.stepError
          location.href='#'
          window.scrollTo(0,0)
        }
      },
      nextStep() {
        this.curStep++
        window.scrollTo(0,0)
      },
      priorStep() {
        this.curStep--
        window.scrollTo(0,0)
      },
      sendCarData() {
        this.errorMessage = ''
        this.isSendLoading = true

        this.loadingStatus = 'car-data-check'
        this.uploadedImages = 0
        if (this.carData.pictures.length > 0)
          setTimeout(() => {this.loadingStatus = 'car-picture-upload'}, 3000)

        for (let i = 1; i < 5; i++) {
          if (!this.isStepDataValid(i)) {
            this.curStep = i
            this.setSendingError(this.stepError)
            location.href='#'
            window.scrollTo(0,0)
            return
          }
        }

        let data = {
          id: -1,
          brand: this.carData.brand,
          model: this.carData.model,
          firstRegistrationDate: this.carData.firstRegistrationDate,
          distance: this.carData.distance,
          power: this.carData.power,
          driveType: this.carData.driveType,
          gearType: this.carData.gearType,
          fuelType: this.carData.fuelType,
          firstName: this.carData.contact.firstName,
          surName: this.carData.contact.surName,
          phone: this.carData.contact.phone,
          email: this.carData.contact.email,
          remarks: this.carData.remarks
        }

        CpaDataService.sendCarData(data)
            .then(response => {
              if (response.status === 200) {
                data.id = response.data.vehiclesDataId
                this.uploadImages(data.id)
                this.sendUploadFinishedSuccess(data.id)
              } else {
                this.setSendingError('Senden fehlgeschlagen - Überprüfen Sie bitte Ihre eingegebenen Daten')
              }
            })
            .catch(e => {
              this.setSendingError('Senden fehlgeschlagen (' + e + ') - Versuchen Sie es bitte später erneut.')
            })
      },
      uploadImages(vehiclesDataId) {
        for (let i = 0; i < this.carData.pictures.length; i++) {
          if (this.carData.pictures[i].uploaded === 'ok') continue

          CpaDataService.uploadImage(vehiclesDataId, this.carData.pictures[i].file)
                        .then(response => {
                          if (response.status === 200)  {
                            this.carData.pictures[i].uploaded = 'ok'
                            this.uploadedImages++
                            this.sendUploadFinishedSuccess(vehiclesDataId);
                          } else {
                            this.errorMessage = 'Fehler beim Hochladen einer Bilddatei - Überprüfen Sie bitte Ihre Bilddatein.'
                            this.carData.pictures[i].uploaded = 'failed'
                          }
                        })
                        .catch(e => {
                          this.errorMessage = 'Senden fehlgeschlagen (' + e + ') Versuchen Sie es bitte später erneut.'
                          this.carData.pictures[i].uploaded = 'failed'
                        })
        }
      },
      sendUploadFinishedSuccess(dataId) {
        if (this.carData.dataUploadedFinished) return

        if (this.errorMessage) return
        
        for (let i = 0; i < this.carData.pictures.length; i++) {
          if (this.carData.pictures[i].uploaded !== 'ok') return
        }

        CpaDataService.sendUploadFinished(dataId)
                              .then(response => {
                                if (response.status === 200 && !this.errorMessage) {
                                  this.isSendLoading = false
                                  this.curStep = 5
                                  this.carData.dataUploadedFinished = true
                                } else {
                                  this.setSendingError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut')
                                }
                              }).catch(e => {
                                this.setSendingError('Ein Fehler ist aufgetreten.(' + e + ') Bitte versuchen Sie es erneut')
                              })
        
      },
      setSendingError(msg) {
        this.isSendLoading = false
        this.errorMessage = msg
      },
      isStepDataValid(step) {
        this.errorFields = []
        this.stepError = ''

        if (step === 1) {
          //// check reqquired fields ////
          if (!this.carData.brand) { this.errorFields.push('[brand]') }
          if (!this.carData.model) { this.errorFields.push('[model]') }
          if (!this.carData.firstRegistrationDate) { this.errorFields.push('[firstRegistrationDate]') }
          if (!this.carData.distance) { this.errorFields.push('[distance]') }
          if (!this.carData.power) { this.errorFields.push('[power]') }
          if (!this.carData.fuelType) { this.errorFields.push('[fuelType]') }
          if (!this.carData.driveType) { this.errorFields.push('[driveType]') }
          if (!this.carData.gearType) { this.errorFields.push('[gearType]') }

          if (this.errorFields.length > 0) { this.stepError = 'Bitte füllen Sie alle erforderlichen Felder aus!'; return false}

          //// check number fields ////
          let distance = this.carData.distance;
          let power = this.carData.power;
          if (isNaN(parseInt(distance)) || distance < 1 || distance >= 9999999) { this.errorFields.push('[distance]') }
          if (isNaN(parseInt(power)) || power < 1 || power >= 9999999) { this.errorFields.push('[power]') }
          
          if (this.errorFields.length > 0) { this.stepError = 'Geben Sie ein gültiges Zahlenformat ein. (ohne Dezimal)'; return false }

        }
        if (step === 2) {
          //// check image num ////
          if (this.carData.pictures.length > 20) { this.stepError = 'Es sind maximal 20 Bilder erlaubt.'; return false}

          //// check image size ////
          for (let i = 0; i < this.carData.pictures.length; i++) {
              if (this.carData.pictures[i].file.size > 8000000) {
                this.stepError = "Bild Datei zu groß! max. 8 MB erlaubt"
                return false
              }
            }

        }
        if (step === 3) {
          //// check contact fields ////
          if (!this.carData.contact.firstName && !this.carData.contact.surName) { this.errorFields.push('[firstName]'); this.errorFields.push('[surName]') }
          if (!this.carData.contact.phone && !this.carData.contact.email) { this.errorFields.push('[phone]'); this.errorFields.push('[email]') }

          if (this.errorFields.length > 0) { this.stepError = 'Bitte füllen Sie alle erforderlichen Felder aus'; return false }
        }
        if (step === 4) {
          //// check remarks field char length ////
          if (this.carData.remarks.length > 400) { this.stepError = 'maximal 400 Zeichen erlaubt'; return false }

          //// Accept Privacy ////
          if (!this.carData.acceptPrivacy) { this.errorFields.push('[acceptPrivacy]'); this.stepError = 'Bitte füllen Sie alle erforderlichen Felder aus'; return false }

        }

        return true
      },
      setDefaultCarData() {
        this.curStep = 1
        this.carData = {
          brand: '',
          model: '',
          firstRegistrationDate: '',
          distance: '',
          power: '',
          driveType: null,
          gearType: null,
          fuelType: null,
          pictures: [],
          contact: {
            firstName: '',
            surName: '',
            phone: '',
            email: ''
          },
          remarks: '',
          acceptPrivacy: false,
          dataUploadedFinished: false
        }
      }
    }
  }
</script>
import http from '../http-common'

class CpaDataService {

    getAllBrands() {
        return http.get('/vehicles/brands')
    }

    sendCarData(data) {
        return http.post('/vehicles', data)
    }

    uploadImage(id, image) {
        const formData = new FormData();
        formData.append('file', image)

        return http.put(`/vehicles/${id}/image`, formData)
    }

    sendUploadFinished(id) {
        return http.post(`/vehicles/${id}/uploadfinished`)
    }

}

export default new CpaDataService()